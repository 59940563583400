

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/demenagement-parent-effet-garde-enfant-modification-avocat.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost36 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Déménagement d’un parent : est-ce que l’enfant suit ou reste? Conseils d’avocat en droit de la famille! - Soumissions Avocat"
        description="Avec la mobilité grandissante sur le marché du travail et les réalités familiales de plus en plus complexes, il y a lieu de se demander quel est le sort réservé aux enfants dans ce monde pour le moins tumultueux. Plus pertinent encore, qu’est-ce que le droit a à dire au LIRE PLUS"
        image={LeadImage}>
        <h1>Déménagement d’un parent : est-ce que l’enfant suit ou reste? Conseils d’avocat en droit de la famille!</h1>

					
					
						<p>Avec la mobilité grandissante sur le marché du travail et les réalités familiales de plus en plus complexes, il y a lieu de se demander quel est le sort réservé aux enfants dans ce monde pour le moins tumultueux. Plus pertinent encore, qu’est-ce que le droit a à dire au sujet du déménagement d’un parent dans une autre ville qui souhaite voir son enfant le suivre vers sa nouvelle destination? La réponse n’a rien de simple, vous en conviendrez!</p>
<p><StaticImage alt="demenagement-parent-effet-garde-enfant-modification-avocat" src="../images/demenagement-parent-effet-garde-enfant-modification-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Si une telle situation semble caractériser la vôtre, les services d’un avocat en droit de la famille pourraient vous être fort utiles, que vous soyez parent déménageant à l’extérieur ou celui qui demeure. Que vous soyez déjà séparés ou non et que la demande porte sur la garde en plus du divorce, il y a une marche à suivre précise pour obtenir gain de cause. La voici!</p>
<h2>Demande de modification de garde et médiation familiale</h2>
<p>À moins que vous vous sépariez au même moment que l’un des conjoints de votre couple déménage, il est probable que votre situation familiale fasse déjà l’objet d’une entente sur la garde des enfants. Celle-ci a évidemment un caractère contraignant que les deux parents ont le devoir de respecter, car il s’agit d’une forme d’obligation contractuelle. De ce fait, le déménagement envisagé aura toujours un impact sur le respect des modalités de l’entente.</p>
<p>Comme la cour sanctionne les ententes de garde d’enfant, il faudra s’adresser à elle pour la modifier, car la loi prévoit que le jugement rendu par un juge est valable jusqu’à ce que l’enfant atteigne les 18 ans. Toutefois, tout changement de circonstance ne justifiera pas une demande de modification; il faudra qu’il s’agisse d’un <strong>changement de circonstances</strong> important. C’est le cas du déménagent d’un des parents. Voici ce qu’il vous faudra savoir!</p>
<p><strong>À quelle cour devez-vous adresser la demande?</strong> Il n’y a qu’une seule cour compétente pour entendre les litiges sur le droit de la famille au Québec, et il s’agit de la Cour du Québec, chambre de la famille. Une demande introductive d’instance en modification de garde d’enfant devra lui être présentée dans les meilleurs délais pour faire modifier le jugement.</p>
<p><strong>Comment se déroulera le processus?</strong> Chaque partie se présentera devant la cour pour présenter ses arguments et ses propositions concernant la garde. Il est possible de soumettre une proposition conjointe, mais il va de soi que lorsque les parents sont en fort désaccord, il faudra que les avocats de chacun fassent valoir lesquelles des propositions sont dans le meilleur intérêt de l’enfant.</p>
<p><strong>Quelles sont les différentes issues possibles?</strong> Le juge, après avoir entendu les parties, évaluera les propositions et les prétentions des deux parents et établira les conditions de la garde. Il n’est en aucun cas lié par ce que les parents proposent : il peut fixer les modalités qui conviennent mieux à l’enfant, en dépit des préférences de ses parents.</p>
<p>Ainsi, dans <strong>le cas d’un déménagement</strong>, le juge chargé d’entendre la cause pourra ordonner que l’enfant suive le parent qui déménage ou qu’il demeure avec celui qui ne bouge pas. Vous verrez cependant que dans les critères énumérés ci-bas, la plupart favoriseront les parents qui restent plutôt que celui qui déménage!</p>
<p><strong>Dans tous les cas, protégez-vous avec un écrit! </strong>Les modalités entourant la garde de l’enfant devraient toujours être mises par écrit, qu’elles changent des aspects importants ou secondaires.</p>
<h2>Le recours devant le juge : les critères pris en compte!</h2>
<p>Vous voici rendu devant l’étape redoutée, celle de l’audience devant le juge. Nous parlerons ici des critères qui sont surtout pris en compte par le juge lorsque les parents ne s’entendent pas sur les modalités de la garde et qu’ils ne parviennent pas à s’entendre. Bien que le juge ait un mot à dire même lorsque les parents s’entendent si le meilleur intérêt de l’enfant pouvait être compromis, nous examinerions plutôt les critères applicables dans des scénarios particuliers, comme le déménagement d’un parent!</p>
<p><StaticImage alt="recours-juge-droit-famille-garde-enfant-demenagement-avocat" src="../images/recours-juge-droit-famille-garde-enfant-demenagement-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Voici donc les balises que le juge suivra pour déterminer s’il est préférable pour l’enfant de suivre le parent qui déménage ou de demeurer avec l’autre.</p>
<p><strong>L’impact des changements sur l’enfant : </strong>Un juge peut demander à un enfant ce qu’il pense de sa propre garde et, dans bien des cas, il le fera afin de guider sa décision. Il va de soi qu’en pareilles circonstances, plus l’enfant est âgé et mature, plus son opinion a de la valeur aux yeux du juge. Ainsi, le magistrat aura la tâche d’évaluer l’impact que la relocalisation aurait sur l’enfant. La stabilité dans la situation de celui-ci est le principal intérêt à préserver.</p>
<p><strong>
Les pertes occasionnées par la relocalisation :</strong> L’enfant perd-il un réseau de soutien, de la famille, des amis proches, etc. Lorsqu’un enfant se trouve dans une situation familiale où il est proche de ses oncles, tantes, cousins et de sa famille élargie en général, le juge accordera une grande valeur à cette situation. Comme la relation avec la famille élargie est un élément du bien-être affectif de l’enfant, le relocaliser irait à l’encontre de ses intérêts.</p>
<p><strong>
Les moyens financiers du parent déménagé :</strong> Les moyens financiers sont toujours un aspect important, quoique rarement déterminants lors de demandes de gardes d’enfants. Si le juge s’assure que le parent se faisant confier la garde partagée ou exclusive a les moyens adéquats pour assurer le bien-être et l’éducation de l’enfant, le fait que l’un d’eux gagne plus que d’autre ne pèse pas si lourd dans la balance. En matière de déménagement d’un parent, ce critère sera tout de même pris en compte.</p>
<p><strong>
La volonté de l’enfant :</strong> Tel que mentionné, il y a une corrélation importante entre l’âge/maturité de l’enfant et l’importance qu’un juge accordera à la volonté exprimée. Par exemple, un enfant de 14 ans qui ne souhaite pas aller vivre avec le parent qui déménage à l’étranger se fera prendre beaucoup plus au sérieux qu’un enfant en bas âge. Rendus à l’adolescence, les juges prennent les avis même très au sérieux.</p>
<p><strong>
Les motifs reliés au déménagement :</strong> Le parent déménage-t-il dans un élan d’aventure et de folie ou est-il simplement contraint de se relocaliser pour des fins professionnelles. Notez bien qu’il s’agit ici d’un critère à caractère plutôt résiduel qui n’est pas toujours appliqué. Il sert surtout à punir la mauvaise foi d’un parent qui lève les feutres, mais il n’est invoqué que sporadiquement.</p>
<p><strong>
La disponibilité des services scolaires : </strong>Lequel des deux environnements offerts par les parents est-il plus propice pour le développement de l’enfant. Ce dernier en est-il à sa première année de fréquentation scolaire ou est-il rendu au début du secondaire, étape à laquelle sa vie sociale est déjà bien développée.</p>
<p><strong>
La capacité de l’enfant :</strong> On parle ici des caractéristiques personnelles de l’enfant et non d’un trouble quelconque qui pourrait l’affliger. Par exemple, un jeune souffrant d’anxiété, de problèmes d’adaptation et de réussite scolaire a un besoin qui prime sur les autres, soit celui de la stabilité. Il sera donc difficile de convaincre un juge que de déménager dans un environnement inconnu loin de l’autre parent est dans son meilleur intérêt.</p>
<p><strong>
La santé physique et psychologique du parent qui déménage :</strong> Il est facile d’avoir de la volonté, mais démontrer une capacité au même niveau est une autre histoire. C’est le rôle du juge d’évaluer l’aptitude, la capacité et les moyens dont fait preuve le parent qui déménage.</p>
<p><strong>
La distance et le contact avec l’autre parent :</strong> Plus le parent déménage loin, plus le maintien d’un contact entre le parent et l’enfant sera difficile. Comme le parent qui déménage est le principal responsable de cette possible aliénation des liens parentaux, le juge risque plutôt de trancher en faveur de l’autre.</p>
<p><strong>
Les termes de la convention de garde déjà en place :</strong> Comme nous sommes, la plupart du temps dans les situations de déménagement, en présence d’une demande de révision/changement des modalités de la garde, il est normal que le juge interprète la convention déjà en place pour le guider.</p>
<p>Prenez note que la loi ne stipule pas que les critères sont cumulatifs ou qu’ils doivent être pris dans son ensemble. Ce sont plutôt des principes qui guident le juge et l’amènent à exercer sa discrétion judiciaire de façon éclairée.</p>
<h2>La révision de la décision est-elle possible?</h2>
<p>La décision rendue par le juge stipulera les modalités de garde que les parents (et les enfants par la force des choses) devront respecter. Nous l’avons déjà dit et répété, mais la stabilité est la véritable boussole de toute l’opération et c’est elle qui dicte la marche à suivre.</p>
<p>Vous l’aurez donc deviné, il faut être en présence de changements importants dans les circonstances familiales pour qu’un juge révise l’entente. On dit également que le changement du jugement de garde soit nécessaire pour continuer le respect des obligations parentales et de garde.</p>
<p>Il doit également y avoir un certain niveau d’imprévu et de gravité de circonstances pour qu’un juge révise l’entente. Lorsqu’un tel constat ne sera pas fait, le juge maintiendra les modalités actuelles et le jugement demeurera valide jusqu’à la majorité de l’enfant.</p>
<h2>Qu’est-ce qu’un avocat peut faire pour vous dans une situation de déménagement?</h2>
<p>Le nerf de la guerre, le choix de votre représentant juridique! Un avocat en droit de la famille est un expert particulier, en ce sens qu’il a accès à des informations on ne peut plus sensibles sur votre vie familiale. N’ayez crainte, ces avocats sont tenus au secret professionnel et les audiences, en droit de la famille, se tiennent toujours à huis clos.</p>
<p><StaticImage alt="service-avocat-demenagement-modification-garde-pension-alimentaire" src="../images/service-avocat-demenagement-modification-garde-pension-alimentaire.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Préparer la demande de modification de garde :</strong> Avant même de vous présenter en cour vous avec un nombre impressionnant de formalités administratives et légales à effectuer. Elles impliquent notamment de présenter les bonnes demandes au bon moment et aux bonnes instances. Heureusement, cela fait partie de la description de tâche de l’avocat.</p>
<p>Vous représenter en cour : Le jour de l’audience, le parent qui détient le meilleur argument n’ira pas plus loin que l’autre s’il est piètrement représenté. La façon de présenter une cause à la magistrature joue pour beaucoup sur la décision rendue par le juge. Le droit de la famille est une spécialité au sein du monde légal, alors sollicitez un spécialiste!</p>
<p><strong>Demander la tenue d’une médiation :</strong> La médiation étant une façon alternative de résoudre un différend, les deux parents doivent être d’accord pour participer à une séance de médiation familiale.</p>
<p><strong>Vous accompagner pour tout recours accessoire : </strong>Il arrive fréquemment que les demandes de garde sont accompagnées de demandes en divorce, de pension alimentaire et d’autres demandes connexes. Un seul et même avocat peut s’occuper de toutes ces demandes pour vous! D’ailleurs, comme la pension alimentaire et le temps qu’un parent passe avec son enfant sont intimement liés, il est normal que ces recours aient main dans la main.</p>
<h2>Confiez votre recherche d’avocat à Soumissions Avocat!</h2>
<p>Dans une situation de déménagement, les problèmes ont le potentiel d’être nombreux et la complexité d’une vie familiale peut rapidement augmenter. Il est donc d’une importante primordiale que la demande de modification de garde dans un contexte de déménagement se fasse dans le respect.</p>
<p>Un tel tact professionnel n’est retrouvé que chez les avocats en droit de la famille les <strong>plus réputés dans la province. C’est la mission de Soumissions Avocat de vous mettre en contact avec eux!</strong></p>
<p><strong>Tout ce que vous avez à faire, c’est de remplir le formulaire au bas de la page et vous serez rapidement mis en contact avec les meilleurs avocats dans votre région du Québec!</strong></p>
    </SeoPage>
)
export default BlogPost36
  